import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Fixed } from "@components/feature-list";
import StartButton from "@components/StartButton";

const EftposCards = ({ getStarted, reverse }) => {
  const images = useStaticQuery(graphql`
    query {
      xs: file(name: { eq: "concierge" }) {
        sharp: childImageSharp {
          fixed(width: 438) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      sm: file(name: { eq: "concierge" }) {
        sharp: childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      md: file(name: { eq: "concierge" }) {
        sharp: childImageSharp {
          fixed(width: 560) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const sources = [
    images.xs.sharp.fixed,
    {
      ...images.sm.sharp.fixed,
      media: `(min-width: 640px AND max-width: 767px)`,
    },
    {
      ...images.md.sharp.fixed,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <Fixed
      id="physical-eftpos"
      description={<Description getStarted={!!getStarted}/>}
      image={sources}
      reverse={!reverse} />
  );
}

const Description = ({ getStarted }) => (
  <div>
    <div>
      <h2>
        <span className="block text-base text-highlight font-semibold tracking-wide uppercase">
          Concierge
        </span>

        <span className="block text-3xl font-bold tracking-tight text-gray-900 mt-1">
          Customer Service Desk
        </span>
      </h2>
      <p className="mt-4 text-lg text-gray-500">
        Industry’s leading secure, flexible, robust gift card platform.
      </p>
      <p className="mt-4 text-lg text-gray-500">
        WAIVPAY’s gift card point of sale solution (GiVVPOS)
        delivers these goals to your business.
      </p>
      <p className="mt-4 text-lg text-gray-500">
        For all your gift card processing, production, and fulfillment requirements, WAIVPAY makes 
        it easy to start a gift card program tailored to your needs. Using our comprehensive web 
        portal, GiVVPOS provides real time management for all aspects of your gift card program.
      </p>
      <p className="mt-4 text-lg text-gray-500">
        Load gift cards from any online PC, laptop, or mobile device.
        Single and bulk loading options for corporate or marketing incentives.
        Search, track and access real time reporting of all issued gift cards and transactions.
        User multi-permissions to manage your corporate operational, managerial, and executive reporting needs.
      </p>

      {
        getStarted &&
          <div className="mt-6">
            <StartButton to="/giftcards" />
          </div>
      }
    </div>
  </div>
);

export default EftposCards;
